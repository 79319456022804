/*
 =========================================================
 * Black Dashboard React v1.2.0 based on Black Dashboard - v1.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Core

@import "black-dashboard-react/custom/functions";
@import "../../../node_modules/bootstrap/scss/functions";

@import "black-dashboard-react/custom/variables";
@import "../../../node_modules/bootstrap/scss/variables";

@import "black-dashboard-react/custom/mixins";
@import "../../../node_modules/bootstrap/scss/mixins";

// Bootstrap components

@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/images";
@import "../../../node_modules/bootstrap/scss/code";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/tables";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/button-group";
@import "../../../node_modules/bootstrap/scss/input-group";
@import "../../../node_modules/bootstrap/scss/custom-forms";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/card";
@import "../../../node_modules/bootstrap/scss/breadcrumb";
@import "../../../node_modules/bootstrap/scss/pagination";
@import "../../../node_modules/bootstrap/scss/badge";
@import "../../../node_modules/bootstrap/scss/jumbotron";
@import "../../../node_modules/bootstrap/scss/alert";
@import "../../../node_modules/bootstrap/scss/progress";
@import "../../../node_modules/bootstrap/scss/media";
@import "../../../node_modules/bootstrap/scss/list-group";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/tooltip";
@import "../../../node_modules/bootstrap/scss/popover";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/utilities";
@import "../../../node_modules/bootstrap/scss/print";

// Custom components

@import "black-dashboard-react/custom/alerts.scss";
@import "black-dashboard-react/custom/badges.scss";
@import "black-dashboard-react/custom/buttons.scss";
@import "black-dashboard-react/custom/dropdown.scss";
@import "black-dashboard-react/custom/footer.scss";
@import "black-dashboard-react/custom/forms.scss";
@import "black-dashboard-react/custom/images.scss";
@import "black-dashboard-react/custom/modal.scss";
@import "black-dashboard-react/custom/navbar.scss";
@import "black-dashboard-react/custom/type.scss";
@import "black-dashboard-react/custom/tables";
@import "black-dashboard-react/custom/checkboxes-radio";
@import "black-dashboard-react/custom/fixed-plugin";
@import "black-dashboard-react/custom/sidebar-and-main-panel.scss";
@import "black-dashboard-react/custom/misc.scss";
@import "black-dashboard-react/custom/rtl.scss";
@import "black-dashboard-react/custom/input-group.scss";

// Vendor / Plugins

// @import "black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss";

// light mode

@import "black-dashboard-react/custom/white-content.scss";

// Cards

@import "black-dashboard-react/custom/card";
@import "black-dashboard-react/custom/cards/card-chart";
@import "black-dashboard-react/custom/cards/card-map";
@import "black-dashboard-react/custom/cards/card-user";
@import "black-dashboard-react/custom/cards/card-task";
@import "black-dashboard-react/custom/cards/card-plain";

// React differences
@import "black-dashboard-react/react-differences/react-differences";

/* This will affect the whole document */
* {
    scrollbar-width: thin;
    scrollbar-color: rgba(155, 155, 155, 0.7) transparent;
  }
  
  /* This is for Webkit browsers like Chrome, Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  
  *::-webkit-scrollbar-track {
    background: transparent;
  }
  
  *::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.7);
    border-radius: 20px;
    border: 3px solid transparent;
  }


  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }


  .user {
    background-color: lightseagreen;
    padding: 10px;
    width:70%;
    border-radius: 10px;
    margin: 20px 0 20px 0;
    /* other styles for user messages */
  }

  .problem-cell {
    color: black;
    cursor: default;
  }
  
  .problem-cell:hover {
    color: blue;
    cursor: pointer;
  }

  .problem-search-input {
    padding: 5px;
      border: none;
      border-radius: 5px;
      margin-left: 10px;
      width: 150px;
      background-color: #444;
      color: #fff;
    
      &:focus {
        outline: none;
      }
  }

  .highlight {
    text-decoration: underline;
    background-color: yellow;
  }

  ::selection {
    background: yellow; /* Background color for text selection */
    color:black;
  }


  .LoginForm {
    /* fallback for old browsers */
    height: 100vh;
    width: 100vw;
    background: #6a11cb;
  
    /* Chrome 10-25, Safari 5.1-6 */
      background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
    
      /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
      background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
    }
    
    .LoginForm #formControlLg {
      padding: 10px !important;
    }
    
    ;
    
    // .login-bottom-padding {
    //   height: max-content;
    // }

  // .login-bottom-padding {
  //   height: max-content;
  // }


  // .select-small {
  //   width: 250px;
  //   font-size: 12px;
  //   color: #f5f5f5;
  // }
  
  // /* Apply these styles for medium-sized screens and down */
  // @media (max-width: 800px) {
  //   .select-small {
  //     width: 250px;
  //     font-size: 10px;
  //   }
    
  // }

  // @media (min-width: 1100px) {
  //   .select-small {
  //     width: 170px;
  //     font-size: 10px;
  //   }
    
  // }
    // hack to prevent expansion issue
    .signup-button, .login-button {
      max-width: 150px;
      max-height: 50px;
    }

 

    .horizontal-list {
      list-style-type: none; /* Remove default list styling */
      padding: 0; /* Remove default padding */
      display: flex; /* Display items in a row */
      flex-wrap: wrap; /* Allow items to wrap */
      align-items: center; /* Align items vertically */
    }
    
    .horizontal-list li {
      margin-right: 10px; /* Space between items and their separators */
    }
    
    .horizontal-list li:not(:last-child)::after {
      content: "•"; /* Dot as a separator */
      margin-left: 10px; /* Space between item and separator */
    }